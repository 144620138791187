<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
     <div class="text-center">
      <h4 class="mt-5">
Courses Durations

       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save" ref="form">
        <b-row>
     
            <b-col cols="4">
              <b-form-group
                label="Minimum Days"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Number Of days"
                  rules="required"
                  
                >
                  <b-form-input type="number" v-model="addCourseForm.min" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Maximum Days"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Maximum Days"
                  rules="required"
                  
                >
                  <b-form-input type="number" v-model="addCourseForm.max" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Title"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                         <b-form-input v-model="addCourseForm.title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
         

          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <duration-list ref="table" />
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";
import DurationList from "./DurationList.vue";

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const refPreviewE2 = ref(null);
    const Tabimage = ref("");
    const coursesList = ref([]);

    store.dispatch("categories/AllCategory").then((response) => {
      console.log("response", response.data.data);
      coursesList.value = response.data.data;
    });
    const addCourseForm = reactive({
      max: "",
      min: "",
      title: "",
   
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const form=ref()
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
    const addCourseFormvalidate = ref();
    const table=ref()

    const save = () => {
      console.log(addCourseForm);
      const formData = new FormData();

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          formData.append("title", addCourseForm.title);
          formData.append("min_days", addCourseForm.min);
          formData.append("max_days", addCourseForm.max);
         
          store
            .dispatch("setting/addDuration", formData)
            .then((response) => {
                    form.value.reset()
                      table.value.refetchData()
              Vue.swal({
                title: "Duration Added ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

          console.log("alt_header_image", addCourseForm);
        }
      });
    };
    const tableColumns = [
      { key: "id", label: "Title",  },
      { key: "name", label: "Minimum Days",  },
      { label: "Maximum Days", key: "link_id",  },

      { key: "actions" },
    ];
    return {
      refInputEl,
      refPreviewEl,
      tableColumns,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
form,
DurationList,
table,
      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    DurationList
},

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
